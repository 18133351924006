<template>
  <div class="policy">
    <!-- Header -->
    <section class="head">
      <div class="left">
        <a @click.prevent="goTo('/profile')">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span>服務條款</span>
      <div class="right"></div>
    </section>
    <section class="main">
      <html>
        <body>
          <span
            >【點點全球股份有限公司】(以下稱「本公司」)提供之「快一點」（以下稱「本服務」）非常重視並尊重您的隱私權。為了幫助您瞭解本服務如何收集、應用及保護您的個人資訊，請詳細閱讀以下個人資料授權原則及使用同意書(以下稱「本同意書」)。由於本公司必須收集以下個人資料及使用同意，才能確保服務持續性並保障您的權益，因此若您不同意以下本同意書之全部或部分內容，請您停止使用本服務。為尊重您的選擇，您有權隨時撤回對本同意書之同意。</span
          >
          <ol>
            <li>
              本同意書之範圍：本同意書適用於您使用本服務或向本服務透過電話、傳真、電子郵件或其他傳輸方式提出查詢、點餐或行銷活動時，所涉及之個人資料蒐集、處理與利用行為。您瞭解並同意，若您使用本服務時有點擊或瀏覽本公司或本服務以外之第三方連結或網站，您的個人資料將適用該第三方之個人資料保護政策，本同意書並不適用。
            </li>
            <li>
              個人資料蒐集之目的:
              提供您使用本服務，並協助本公司運營所必要之一切目的，包含但不限於本服務之運作、行銷、廣告、會員管理、數據統計、優化、第三人之業務合作、驗證您的身分、協助執法機關及政府機關執行公務。
            </li>
            <li>
              個人資料蒐集類型：本公司可能會蒐集到包含但不限於您的姓名、出生年月日、國民身分證統一編號、護照號碼、特徵、指紋、婚姻、家庭、教育、職業、聯絡方式、財務情況、社會活動及其他與本服務相關而得以直接或間接方式識別您之個人資料（包含但不限於LINE
              User ID、信用卡資訊、IP地址、憑證序號、銀行帳戶等）。
            </li>
            <li>
              適當安全維護措施：依「個人資料保護法施行細則」（下稱「個資法施行細則」）第12條，適當安全維護措施指廠商為防止個人資料被竊取、竄改、毀損、滅失或洩漏，採取技術上
              及組織上 之措施 ；前述 措施得包括配置 管理之人員及相當資源、界定
              個人資料之範圍、個人資料之風險評估及管理機制、事故 之預防、 通報
              及應
              變機制、個人資料蒐集、處理及利用之內部管理程序、資料安全管理及人員管理、認知宣導及教育
              訓練 、設備 安全管理、資料安全稽核 機制、使用 紀錄 、軌跡
              資料及證據保存及個人資料安全維護之整體持續改
              善。本公司將依此提供符合相關法令規定 之技 術與安全維護措施，以妥善
              保護您的個人資料。
            </li>
            <li>
              個人資料利用之期間： 自您開始 使用本服務時，至您要
              求停止使用本服務之時，或本公司停止向您提供本服務為止（以時間較早者為準）。
            </li>
            <li>
              個人資料利用之地區：同意將您的個人資料利用於本公司執行業務、運營本服務及伺服器存放之地區。
            </li>
            <li>
              個人資料之分享:
              除以下任一情況外，本公司不會將您的個人資料提供於本公司以外之第三人：(1)於事先
              取得您同意之情形下；(2)本公司得將個人資訊提供給本公司之海內外關係企
              業、合作夥伴(例如:提供餐點之商家或LINE)、委外或協力廠商(例如:行銷公司、廣告公司、系統維護公司、系統服務供應商、系統建置
              公司、數據分析公司)、經本公司合法授權之第三方、以及本服務信任之第三方或人員；(3)須提供您的個人資料予第三人，始能提供
              你所要求之服務 ；(4)政府機
              構或執法機關，或本公司基於法律上之原因而須提供。本公司會確保上述
              第三方於處理過程中遵從本服務之指示及本同意書與相關法令之規範。
            </li>
            <li>
              個人資料之自主權:
              您可向本公司申請就您提供的資料，依中華民國個人資料保護法之規
              定行使權利，如：(1)查詢或請求閱覽、(2)請求製給複製本、(3)請求補充
              或更正 、(4)請求停止蒐集、處理、利用或(5)請求刪除
              ，然本公司得向您酌收處理 費用以 先行查證您是否
              為個人資料所有人，並要求您提供相關個人資料以協助查證及處理程序。
              倘您的要求將對國家重大利益、公務機關執行法定職務、蒐集機關或第三人之重大利益有
              妨害 時，本公司有權拒絕您的申請。
            </li>
            <li>
              9您瞭解並同意不使用本服務提供之LINE官方帳號作為任何第三人之廣告媒體（包含但不限於透過使用LINE官方帳號宣傳第三人之商品或服務）。
            </li>
            <li>
              您瞭解並同意本公司會向您提供由本公司提供者(LINE Provider)建立
              的通道
              （LINEChannel）綁定由本公司建立的LINE官方帳號並依您指定的名稱命名LINE官方帳號。
            </li>
            <li>
              若您有使用LINE或其他社群服務，本公司及海內外關係企業可能於您使用本服務時，向您詢問是否將本服務之資訊發布於您的社群頁面
              ，若您不同意請勿點選同意鍵，然為尊重您的決定，您亦得隨時向本公司撤回該同意並於您的社群頁面上移除該等資訊。
            </li>
            <li>
              您應確保所提供之個人資料係合法真實且完整正確，若您提供之個人資料有虛偽、錯誤、違反
              法令或侵害 第三人權利，可能導致
              您無法使用本服務，本公司並有權終止對您的服務。
              倘您提供之個人資料因違反上述情形，而 致第三人受有損
              害或受執法機關追訴責任，您瞭解並同意本公司不負擔任何責任。
            </li>
            <li>
              若您不願意提供，或要求本公司停止蒐集、處理、利用或刪除您的個人資料，本公司將尊重您的決定
              ，但您可能因此無法使用本服務或完成相關交易 ，本公司並保留是否
              同意終止對您的服務或完成相關交易之權利。
            </li>
            <li>
              倘未成年人於使用本服務有同意本公司蒐集、利用及處理其個人資訊時，應在法定代理人或監護人之同意下為之。其法定代理人或
              監護人得隨時請求本公司終止該帳號之服務，或請求本同意書第6條個人資料自主權之行為。
            </li>
            <li>
              無論明示或暗示
              ，本公司不保證LINE官方帳號或本服務含有之第三方連結及網站不存在事實上或法
              律上 之瑕疵 （包含但不限於與安全性、可靠性、 正確性、 完整 性、有
              效性、針對特 定目的之適用性、安全等相關之缺陷 、錯誤 、漏 洞、權利
              侵害
              等），您瞭解並同意本公司不就第三方對您引起的任何損害負擔責任。
            </li>
            <li>
              您瞭解此個人資料授權原則及使用同意書符合個人資料保護法及相關法規之要
              求，且瞭解其內容，並同意本同意書所列之事項
              。本同意書如有未盡事宜，本服務將依中華
              民國個人資料保護法或其他相關法規及其 後修訂 之規定辦
              理。本公司將不定時修訂 本同意書，該修訂 之最新版 本將於 發布
              時即當然生效。若您不同意修訂
              之內容，請停止使用本服務，並依本同意書之規定通知本公司停止蒐集、處理、利用或刪除
              您的個人資料。若您於該等修訂後，仍有繼續使用本服務之情事，則視為您同意受最新修訂之同意書內容所拘束。
            </li>
            <li>
              您瞭解網際網路非百分之百安全的 環境，並可能存在事實上或法 律上
              之瑕疵（包含但不限於與安全性、可靠性、 正確性、 完整 性、有 效性、
              針對特 定目的之適用性、安全等相 關之 缺陷 、錯誤 、漏 洞、權利
              侵害 等）， 你同意對個人資料及使用本服務的資訊善盡
              保管義務，對使用本服務的伺服器及裝置 安裝防毒軟體 或相關防護裝置
              ，以 避免 外洩或 遭第三人取用，您須自行對 未善盡 保管 義務所 引起
              之損 害負擔責任，您同時瞭解並同意自行承擔網際網路之環境風險及。
            </li>
            <li>
              Cookies之使用: Cookies是一種小 型檔案
              ，於您使用本服務時會儲存某些資訊 在您的使用
              裝置(例如:電腦、平板、手機)中。您瞭解並同意本公司使用Cookies以利本公司分析、蒐集、保存您的使用模式、
              喜好 ，以 增進
              您使用本服務之體驗、維持本服務之運作、改善本服務之效能、 投放
              適合您的廣告及為維持本公司營運之相關目的。為尊重您的決定，您
              仍可以於您的裝置 瀏覽 器中 取消或限 制此功能。 然當您選擇限制或取
              消Cookies時，可能導致您無法使用本服務之一部或全部之內容。
            </li>
            <li>若您對本同意書所列事項有疑問，請聯絡本公司。</li>
            <li>
              本同意書之解釋、履行及效力悉依中華民國法律為準據法，並以台灣台北地方法
              院為第一審管轄法院。
            </li>
          </ol>
        </body>
      </html>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Policy',
  data() {
    return {};
  },
  methods: {
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
  },
};
</script>
